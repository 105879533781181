import equal from 'fast-deep-equal/react'
import { number, shape, string } from 'prop-types'
import { useState } from 'react'

import {
  ActiveFiltersPropTypes,
  FacetsPropTypes,
  SalePropTypes,
} from '../../shared/prop-types'
import Dialog from '../shared/Dialog'
import Filters from './Filters'
import { get } from '../../../../shared/js/json-fetch'
import PerPageSelect from './PerPageSelect'
import { pluralize } from '../../../../shared/js/formatters'
import SaleCountdown from '../shared/SaleCountdown'
import SortBySelect from './SortBySelect'

const PER_PAGE_BASIS = 32
const PER_PAGE_MAX = PER_PAGE_BASIS * 5

const Catalog = ({
  activeFilters: initialActiveFilters = {},
  entriesCount: initialEntriesCount,
  facets,
  paginationHTML: initialPaginationHTML,
  perPage: initialPerPage,
  resultsHTML: initialResultsHTML,
  resultsLabel: initialResultsLabel,
  sale,
  sortBy: initialSortBy,
}) => {
  const [flash, setFlash] = useState(null)
  // Disable action buttons when search request is pending
  const [pendingSearch, setPendingSearch] = useState(false)
  // The shape of active filters is: { facetId: [facet active filters] }
  const [activeFilters, setActiveFilters] = useState(initialActiveFilters)
  // When filters modal is active (ie on small devices) we expect search not
  // to be triggered until manual validation is done. We therefore have to
  // store active filters before modal is opened in order to make cancellation
  // possible when the user decides not to run search but cancel it.
  const [currentActiveFilters, setCurrentActiveFilters] =
    useState(initialActiveFilters)
  // Manage filters modal on small devices
  const [modalActive, setModalActive] = useState(false)
  // Manage sort filter independently from other filters due to separated display position
  const [sortBy, setSortBy] = useState(initialSortBy)
  // Search results are reloaded on filter change
  const [resultsHTML, setResultsHTML] = useState(initialResultsHTML)
  const [resultsLabel, setResultsLabel] = useState(initialResultsLabel)
  const [entriesCount, setEntriesCount] = useState(initialEntriesCount)
  const [paginationHTML, setPaginationHTML] = useState(initialPaginationHTML)
  const [perPage, setPerPage] = useState(initialPerPage)
  // Prepare pagination choice depending on total entries.
  // Limit to max 120 items per page.
  const perPageList = Array.from(
    Array(Math.ceil(Math.min(entriesCount, PER_PAGE_MAX) / PER_PAGE_BASIS)),
    (_, index) => (index + 1) * PER_PAGE_BASIS
  )

  const catalogContainerClass =
    facets.length > 0 ? 'u-mb-7@us u-mb-14@fs' : 'o-layout-wrap'
  const resultsClasses =
    entriesCount === 0
      ? ''
      : 'o-layout-wrap o-layout-wrap--4-items u-mb-6@us u-mb-11@fs'

  // Calculate active filters count
  const activeFiltersCount = Object.values(activeFilters).reduce(
    (acc, { length }) => acc + length,
    0
  )

  return (
    <>
      <div className={catalogContainerClass}>
        <div>
          {flash && (
            <div className={`c-message c-message--${flash.kind}`}>
              {flash.message}
            </div>
          )}
          {sale && <SaleCountdown {...sale} />}
          <h2 className='u-visually-hidden'>Résultats</h2>
          <div className='o-layout o-layout--end-y u-mb-2'>
            <PerPageSelect
              count={entriesCount}
              label={resultsLabel}
              id='per_page-top'
              perPage={perPage}
              perPageBasis={PER_PAGE_BASIS}
              perPageList={perPageList}
              setPerPage={handlePerPage}
            />
            <div className='o-layout@us'>
              {entriesCount > 1 && (
                <span className='u-hidden@us'>
                  <SortBySelect
                    id='sort-by-screen'
                    onChange={handleSortBy}
                    sortBy={sortBy}
                  />
                </span>
              )}
              <button
                className='c-btn c-btn--filter'
                type='button'
                aria-haspopup='dialog'
                onClick={openModal}
              >
                <svg className='c-btn__icon'>
                  <use xlinkHref='#filter' />
                </svg>
                Filtrer
              </button>
            </div>
          </div>
        </div>

        <div
          className={resultsClasses}
          dangerouslySetInnerHTML={{ __html: resultsHTML }}
        />
        {entriesCount === 0 && activeFiltersCount > 0 && (
          <div className='u-txt-center'>
            <button
              className='c-btn'
              type='reset'
              onClick={cancelFilters}
              disabled={pendingSearch}
            >
              Annuler le filtrage
            </button>
          </div>
        )}
        <div className='o-layout o-layout--end-y'>
          <PerPageSelect
            className='u-hidden@um'
            count={entriesCount}
            id='per_page-bottom'
            label={resultsLabel}
            perPage={perPage}
            perPageList={perPageList}
            setPerPage={handlePerPage}
          />
          {paginationHTML && (
            <div dangerouslySetInnerHTML={{ __html: paginationHTML }} />
          )}
        </div>
      </div>
      <Dialog
        className='right-action-modal'
        onCancel={dismissModal}
        opened={modalActive}
        title={
          <>
            Filtrer les résultats
            {activeFiltersCount > 0 && (
              <small>({pluralize(activeFiltersCount, 'sélectionné')})</small>
            )}
          </>
        }
      >
        <button
          type='button'
          className='right-action-modal-close'
          data-content-back-id='results-filter'
          onClick={dismissModal}
        >
          <span className='right-action-modal-close__text'>Fermer</span>
        </button>
        <Filters
          activeFilters={activeFilters}
          activeFiltersCount={activeFiltersCount}
          deleteFilter={deleteFilter}
          disabled={pendingSearch}
          facets={facets}
          prefix='modal'
          selectFilter={selectFilter}
        />
        <div className='right-action-modal__btn'>
          <button
            className='c-btn c-btn--fill'
            type='reset'
            onClick={activeFiltersCount > 0 ? cancelFilters : dismissModal}
            disabled={pendingSearch}
          >
            {activeFiltersCount > 0 ? 'Annuler le filtrage' : 'Fermer'}
          </button>
          <button
            className='c-btn c-btn--primary'
            onClick={handleFilters}
            disabled={pendingSearch}
          >
            Appliquer
          </button>
        </div>
      </Dialog>
    </>
  )

  // Clear all active filters.
  // Reload search form on large devices.
  function clearFilters() {
    search({ filters: {}, sortBy })
  }

  // Compute URL search params with selected filters
  function computeSearchURL(activeFilters, perPage, sortBy) {
    // Skip URL update on old browsers
    if (!history.pushState || typeof URLSearchParams === 'undefined') {
      return
    }

    const url = new URL(window.location)
    // Fetch queries should use a distinctive .json suffix so the browser
    // doesn't confuse HTML and JSON responses in their cache / back behavior.
    url.pathname += '.json'
    const searchParams = new URLSearchParams(url.search.slice(1))
    // Everytime JS filters change, we must force navigation back
    // to the first page otherwise it could result in an empty page
    // even if filters match some products.
    searchParams.delete('page')
    // Manage per page filter
    searchParams.delete('per_page')
    if (perPage) {
      searchParams.append('per_page', perPage)
    }
    // Manage sort filter
    searchParams.delete('sort_by')
    if (sortBy) {
      searchParams.append('sort_by', sortBy)
    }
    // Loop over every facet to check against active facets+filters.
    // If no filter is selected, then disable facet from search params.
    // We have to loop twice for facets param key removal since `taxon_ids[]`
    // can be used by many facets and would be reset for every related facet.
    for (const { paramKey } of facets) {
      // First, remove search param
      searchParams.delete(paramKey)
    }
    for (const { id, paramKey } of facets) {
      // Then append every search param for facet with active filters values
      const facetActiveFilters = activeFilters[id]
      if (facetActiveFilters) {
        for (const { value } of facetActiveFilters) {
          searchParams.append(paramKey, value)
        }
      }
    }
    url.search = searchParams.toString()
    return url
  }

  // Remove selected filter.
  // Reload search form on large devices.
  function deleteFilter({ id, value }) {
    let updatedActiveFilters = activeFilters[id].filter(
      (filter) => filter.value !== value
    )
    if (updatedActiveFilters.length > 0) {
      updatedActiveFilters = { ...activeFilters, [id]: updatedActiveFilters }
    } else {
      // Remove facet from active filters when no associated filters exist
      updatedActiveFilters = { ...activeFilters }
      delete updatedActiveFilters[id]
    }
    search({ filters: updatedActiveFilters, perPage, sortBy })
  }

  // Hide modal, reset active filters
  function cancelFilters() {
    // Clear filters
    clearFilters()
    // Process search with no filter (ie refresh results and URL)
    search({ filters: [], perPage, sortBy, forceModalSearch: true })
  }

  // Hide modal
  function dismissModal() {
    setModalActive(false)
    setActiveFilters(currentActiveFilters)
  }

  function handleFilters() {
    search({ filters: activeFilters, perPage, sortBy, forceModalSearch: true })
  }

  function handlePerPage({ target: { value } }) {
    const perPage = Number(value)
    search({ filters: activeFilters, perPage, sortBy })
  }

  function handleSortBy({ target: { value: sortBy } }) {
    search({ filters: activeFilters, perPage, sortBy })
  }

  // Open modal, store active filters for later (possible) cancellation
  function openModal() {
    setModalActive(true)
    setCurrentActiveFilters(activeFilters)
  }

  // Add newly selected filter.
  // Reload search form on large devices.
  function selectFilter({ id, label, value }) {
    const selectedFilters = [...(activeFilters[id] || []), { label, value }]
    const updatedActiveFilters = {
      ...activeFilters,
      [id]: selectedFilters,
    }
    search({ filters: updatedActiveFilters, perPage, sortBy })
  }

  async function search({
    filters,
    perPage: updatedPerPage,
    sortBy: updatedSortBy,
    forceModalSearch = false,
  }) {
    // Do not reload search if filters did not change
    if (
      equal(currentActiveFilters, filters) &&
      perPage === updatedPerPage &&
      sortBy === updatedSortBy
    ) {
      // Clear flash
      setFlash(null)
      return
    }

    // Update filters display
    setActiveFilters(filters)
    // Run search when modal is not active (ie NOT on small devices)
    // or if explicitly asked to.
    if (!modalActive || forceModalSearch) {
      setPendingSearch(true)
      // Get search URL
      const url = computeSearchURL(filters, updatedPerPage, updatedSortBy)
      // Call server for catalog partial view update
      const { data, error, flash } = await get(url)
      if (error) {
        setFlash(flash)
        // Restore previous active filters
        setActiveFilters(activeFilters)
      } else {
        // Reload catalog
        setEntriesCount(data.entriesCount)
        setPaginationHTML(data.paginationHTML)
        setResultsHTML(data.resultsHTML)
        setResultsLabel(data.resultsLabel)
        // Store selected "per page" and sort order
        setPerPage(updatedPerPage)
        setSortBy(updatedSortBy)
        // Change browser URL -- ensure no inline JSON format suffix
        history.pushState({}, '', url.toString().replace(/\.json(\??)/, '$1'))
        // Clear flash
        setFlash(null)
        // Hide modal
        setModalActive(false)
        // Enable action buttons
        setPendingSearch(false)
      }
    }
  }
}

Catalog.propTypes = {
  activeFilters: ActiveFiltersPropTypes,
  entriesCount: number.isRequired,
  facets: FacetsPropTypes.isRequired,
  paginationHTML: string,
  perPage: number,
  resultsHTML: string.isRequired,
  resultsLabel: string.isRequired,
  sale: shape(SalePropTypes),
  sortBy: string,
}

export default Catalog
