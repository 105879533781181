import { bool, func, number, shape, string } from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import YouTube from 'react-youtube'

import PlayCircleFilled from '@material-ui/icons/PlayCircleFilled'

// See YouTube documentation for player parameters:
// https://developers.google.com/youtube/iframe_api_reference?hl=fr
export default function Video({
  active,
  dimensions,
  onClick,
  thumb,
  identifier,
}) {
  const videoRef = useRef()
  const className = `video ${active ? ' is-active' : ''} ${
    thumb ? 'c-glide__thumbnail' : 'c-slider__preview'
  }`

  const [player, setPlayer] = useState(null)

  useEffect(() => {
    if (!player) {
      return
    }

    // When using autoplay, all videos are starting at the same time.  This is
    // due to react slick (slider) that loads every block many times (cloned
    // elements).  A workaround is to check if the current component is active
    // using its ref to ensure that only the current video is the one to play
    // (note the cloned ones).
    if (!active) {
      player.stopVideo()
    } else if (videoRef.current?.closest('.slick-active')) {
      player.playVideo()
    }
  }, [active, player])

  if (!thumb) {
    const { width, height } = dimensions
    const opts = {
      height,
      width,
      playerVars: {
        controls: 0,
        fs: 0,
        modestbranding: 1,
      },
    }

    return (
      <div className={className} ref={videoRef}>
        <YouTube
          videoId={identifier}
          opts={opts}
          onEnd={restartPlayer}
          onReady={onReady}
          style={{ marginLeft: -(width - height) / 2 }}
        />
      </div>
    )
  }

  return (
    <button className={className} onClick={handleClick}>
      <PlayCircleFilled />
    </button>
  )

  function handleClick() {
    if (thumb) {
      onClick?.()
    }
  }

  function onReady({ target }) {
    setPlayer(target)
  }

  // Loop video.  This should be done using the player "loop" parameter. Sadly
  // this won't work due to parameter incompatibility with embed player.
  function restartPlayer() {
    player.seekTo(0, true)
  }
}

Video.propTypes = {
  active: bool,
  dimensions: shape({
    width: number,
    height: number,
  }),
  identifier: string.isRequired,
  onClick: func,
  thumb: bool,
}
