import {
  arrayOf,
  bool,
  exact,
  number,
  objectOf,
  oneOf,
  oneOfType,
  string,
} from 'prop-types'

import { ProductOptionPropTypes } from '../../../shared/js/prop-types'

export const AddressPropTypes = {
  address: string.isRequired,
  city: string.isRequired,
  civility: string,
  company: string,
  complement: string,
  country: string,
  countryCode: string,
  firstName: string.isRequired,
  // id is not required because Pickup points address
  // has no record in DB and then no assoiated ID.
  id: number,
  lastName: string.isRequired,
  mobilePhone: string.isRequired,
  zipCode: string.isRequired,
}

export const CountriesPropTypes = arrayOf(
  exact({
    code: string.isRequired,
    name: string.isRequired,
  })
).isRequired

export const DiscountsPropTypes = arrayOf(
  exact({
    id: number.isRequired,
    cents: number.isRequired,
    name: string.isRequired,
    points: number,
    removable: bool.isRequired,
  })
)

export const FilterProps = {
  label: string.isRequired,
  value: oneOfType([string, number]).isRequired,
}

export const FiltersPropTypes = arrayOf(exact(FilterProps))

export const FacetsPropTypes = arrayOf(
  exact({
    id: string.isRequired,
    // Parameter key used when sending data to the server.
    // Some facets may have the same paramKey (ie. taxon_values_ids).
    paramKey: string.isRequired,
    title: string.isRequired,
    filters: FiltersPropTypes.isRequired,
    // Set to 'true' when no sorting is expected
    unsorted: bool,
  })
)

export const ActiveFiltersPropTypes = objectOf(FiltersPropTypes)

// This must be declared *after* ProductOptionPropTypes
// since it is used for internal prop-types.
export const LineItemPropTypes = {
  disabled: bool,
  htmlPicture: string,
  id: number.isRequired,
  jewelleryBag: bool,
  maxQuantity: number.isRequired,
  name: string.isRequired,
  options: arrayOf(exact(ProductOptionPropTypes)),
  quantity: number.isRequired,
  reference: string.isRequired,
  unitCents: number.isRequired,
  unitTaxCents: number.isRequired,
  url: string.isRequired,
}

export const LineItemSimplifiedPropTypes = arrayOf(
  exact({
    quantifiedCents: number.isRequired,
    id: number.isRequired,
    name: string.isRequired,
  })
)

export const PickupPointPropTypes = {
  accessible: bool,
  address: string.isRequired,
  availabilityLabel: string,
  code: string.isRequired,
  countryCode: string.isRequired,
  structuredAddress: exact({
    address1: string.isRequired,
    address2: string,
    city: string.isRequired,
    country_code: string.isRequired,
    export_kind: string.isRequired,
    name: string.isRequired,
    zip_code: string.isRequired,
  }).isRequired,
  daysOffLabel: string,
  detail: string,
  distance: string.isRequired,
  kind: string.isRequired,
  lat: number.isRequired,
  lng: number.isRequired,
  name: string.isRequired,
  openingHours: arrayOf(
    exact({
      dow: string.isRequired,
      start: string,
      end: string,
    })
  ),
}

export const PickupPointsPropTypes = arrayOf(exact(PickupPointPropTypes))

export const PriceDetailsBaseProps = {
  actualShippingAddress: exact(AddressPropTypes),
  cents: number.isRequired,
  deliveryLabel: string,
  discounts: DiscountsPropTypes,
  lineItems: LineItemSimplifiedPropTypes,
  // nextStepURL is not mandatory on last step
  nextStepURL: string,
  selectedPickupPoint: exact(PickupPointPropTypes),
  taxCents: number.isRequired,
  totalCents: number.isRequired,
  shipping: exact({
    name: string,
    cents: number,
  }),
}

// DEV NOTE: keep *after* `PickupPointsPropTypes`
export const CarrierPropTypes = {
  cents: number.isRequired,
  id: number.isRequired,
  name: string.isRequired,
  shippingLabel: string,
  pickupPoints: PickupPointsPropTypes,
}

export const RatingsPropTypes = {
  rating: number.isRequired,
  reviewsCount: number.isRequired,
}

export const SalePropTypes = {
  endsAt: string.isRequired,
  startsAt: string.isRequired,
  url: string,
  kind: oneOf(['flash_sales', 'private_sales', 'soldes']),
}

export const VariantPropTypes = {
  cents: number.isRequired,
  displayWeight: bool,
  id: number.isRequired,
  labels: arrayOf(string).isRequired,
  markShipping: bool,
  purchasable: bool.isRequired,
  quantity: number.isRequired,
  reference: string,
  regularCents: number,
  selected: bool,
  shipping: exact({
    deliveryLabel: string.isRequired,
    label: string.isRequired,
    highlight: bool,
  }),
  weight: number,
}

export const VariantsPropTypes = {
  displayWeight: bool,
  headings: arrayOf(string).isRequired,
  variants: arrayOf(exact(VariantPropTypes)),
}
