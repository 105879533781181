import AddressDialogLink from './customer/AddresssDialogLink'
import Cart from './Cart'
import ConsentBanner from './gdpr/ConsentBanner'
import DatePicker from './shared/DatePicker'
import GuideDialogLink from './product/GuideDialogLink'
import { loadTags } from './gdpr/gdpr'
import mountReactContainer from '../../../shared/js/react-mounting'
import SearchDialogButton from './search/SearchDialogButton'
import TruncatedText from './shared/TruncatedText'

import './product'
import './purchase-process'
import './search'

document.addEventListener('DOMContentLoaded', () => {
  // Load tags before mounting React components
  loadTags()

  mountReactContainer({ id: 'js-cart', component: Cart })
  mountReactContainer({ selector: '.js-date-picker', component: DatePicker })
  mountReactContainer({
    id: 'js-modal-search-button',
    component: SearchDialogButton,
  })
  mountReactContainer({ id: 'js-modal-guide-link', component: GuideDialogLink })
  mountReactContainer({
    selector: '.js-modal-address-link',
    component: AddressDialogLink,
  })
  mountReactContainer({ id: 'js-truncated-text', component: TruncatedText })
  mountReactContainer({
    id: 'js-consent-banner',
    component: ConsentBanner,
  })
})
