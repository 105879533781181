import { arrayOf, func, shape, string } from 'prop-types'

import { PhotoPropType } from './Photo'

export function crunchSrcSet(srcset) {
  return srcset.map(([url, size]) => `${url} ${size}`).join(', ')
}

export function makeArrow({ alt, direction }) {
  const result = ({ className, onClick }) => {
    const disabling = className.includes('slick-disabled')
      ? ' c-pagination__item--disabled'
      : ''

    return (
      <li
        className={`c-pagination__item c-pagination__item--${direction}${disabling}`}
      >
        <button
          className={`c-pagination__link c-pagination__link--${direction}`}
          onClick={onClick}
        >
          <svg
            aria-label={alt}
            className='c-pagination__icon'
            height='18'
            role='img'
            width='18'
          >
            <use xlinkHref='#arrow'></use>
          </svg>
        </button>
      </li>
    )
  }
  // onClick is not always required
  result.propTypes = { onClick: func }
  return result
}

export const MAX_REGULAR_THUMBS = 3

export const SliderCommonPropTypes = {
  slides: arrayOf(
    shape({
      audio: string,
      alt: string,
      main: shape(PhotoPropType),
      thumb: shape(PhotoPropType),
      video: string,
    })
  ).isRequired,
}
