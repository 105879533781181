import { bool, func, oneOf, string } from 'prop-types'

let syntheticIdSuffix = 0

const TextField = ({
  helperText,
  defaultValue,
  disabled,
  errorMessage,
  id,
  label,
  name,
  onChange,
  required,
  type,
  value,
  valid,
  ...extraInputProps
}) => {
  const descriptionId = helperText
    ? `description-input-${id || ++syntheticIdSuffix}`
    : null

  const dynamicLabelProps = helperText
    ? { 'aria-describedby': descriptionId }
    : {}
  const dynamicInputProps = extraInputProps
  if (defaultValue != null) {
    dynamicInputProps.defaultValue = defaultValue
  }
  if (value != null) {
    dynamicInputProps.value = value
  }
  if (typeof onChange === 'function') {
    dynamicInputProps.onChange = ({ target: { value } }) =>
      onChange(value.trimLeft())
  }

  const inputProps = {
    'aria-required': required,
    'aria-invalid': !valid,
    disabled,
    id,
    name,
    ...dynamicInputProps,
  }
  const dynamicInput =
    type === 'textarea' ? (
      <textarea {...inputProps} />
    ) : (
      <input type='text' {...inputProps} />
    )

  return (
    <div className='u-mb-2'>
      <label
        className='c-label c-label--image'
        htmlFor={id}
        {...dynamicLabelProps}
      >
        {label}
      </label>
      {dynamicInput}
      {!valid && <p className='c-message c-message--error'>{errorMessage}</p>}
      {helperText && (
        <p id={descriptionId} className='c-message c-message--help'>
          {helperText}
        </p>
      )}
    </div>
  )
}

TextField.propTypes = {
  defaultValue: string,
  disabled: bool,
  errorMessage: string,
  helperText: string,
  id: string,
  label: string.isRequired,
  name: string,
  onChange: func,
  required: bool,
  type: oneOf(['date', 'email', 'number', 'text', 'textarea']),
  value: string,
  valid: bool,
}
TextField.defaultProps = {
  disabled: false,
  errorMessage: 'Vous devez renseigner un texte',
  required: false,
  type: 'text',
  valid: true,
}

export default TextField
