import { arrayOf, bool, func, number, shape, string } from 'prop-types'
import { useState } from 'react'

import AlmaWidget from './AlmaWidget'
import {
  computeOptionsCents,
  computeOptionsToParams,
  formatOptionsSummary,
} from '../../../../shared/js/options-utils'
import Dialog from '../shared/Dialog'
import { formatPrice } from '../../../../shared/js/formatters'
import { notifyCartBadge } from '../Cart'
import Option from './options/Option'
import OptionsSummaryLabel from '../../../../shared/js/components/OptionsSummaryLabel'
import { ProductOptionPropTypes } from '../../../../shared/js/prop-types'
import { put } from '../../../../shared/js/json-fetch'
import Ratings from './Ratings'
import {
  RatingsPropTypes,
  SalePropTypes,
  VariantsPropTypes,
} from '../../shared/prop-types'
import SaleCountdown from '../shared/SaleCountdown'
import StockAlertButton from './StockAlertButton'
import Variants from './Variants'

// Display product description.
// Manage price update depending on selected options and quantity.
// Triggers add to cart: post data using fetch API.
const Description = ({
  addToCartURL,
  alma,
  authorized,
  brand,
  cart,
  customerEmail,
  guide,
  htmlPicture,
  family,
  id,
  jewelleryBag,
  name,
  initialOptions = [],
  initialVariants,
  relatedProductsHTML,
  ratings,
  sale,
  shippingOfferedThreshold,
  shortDescription,
  stocklessSellable,
}) => {
  const [cartQuantity, setCartQuantity] = useState(cart.quantity)
  const [dialog, setDialog] = useState('')
  const [pending, setPending] = useState(false)
  const [variants, setVariants] = useState(initialVariants?.variants ?? [])
  const [flash, setFlash] = useState(null)
  const [options, setOptions] = useState(initialOptions)
  const [variantsAlerts, setVariantsAlerts] = useState([])

  // Find the current selected variant.  Defaults to first entry
  const selectedVariant =
    variants.find(({ selected }) => selected) || variants[0]
  const {
    cents,
    id: selectedVariantId,
    purchasable,
    regularCents = 0,
    reference,
    shipping: { deliveryLabel },
  } = selectedVariant
  const { optionsCents, optionsTaxCents } = computeOptionsCents(options)
  const totalCents = cents + optionsCents + optionsTaxCents
  const totalRegularCents = regularCents + optionsCents + optionsTaxCents
  // Check cart actual cents, selected variant and options cents for free shipping eligibility
  const freeShipping = cart.cents + totalCents >= shippingOfferedThreshold
  const sellable = stocklessSellable || selectedVariant.quantity > 0
  const addableToCart = sellable && !pending && purchasable && authorized
  const optionsWrapperClasses =
    options.length > 1 ? 'u-mb-2@us u-mb-3@fs' : 'u-mr-2 u-mb-2'

  // Options are disabled when product is not purchasable or when customer is
  // currently adding it to the cart.
  const optionsDisabled = !addableToCart || pending

  return (
    <>
      <header className='c-product-information__wrapper'>
        {/* Info Header */}
        <div className='o-order'>
          <h1 className='c-product-information__title'>{name}</h1>
          {/* Doublonnage du prix pour un affichage en sticky sur mobile */}
          <span className='c-price c-price--sticky'>
            <span className='c-price__amount'>{formatPrice(totalCents)}</span>
          </span>
          {sale && <SaleCountdown {...sale} />}
          {ratings && <Ratings {...ratings} />}
        </div>
        <div className='c-product-information__reference'>
          {reference && (
            <>
              <abbr title='Référence'>Réf.</abbr>
              {` ${reference}`}
            </>
          )}
        </div>

        {/* Price + Alma and shipping */}
        <span className='c-price u-mr-2'>
          <ins>{formatPrice(totalCents)}</ins>{' '}
          {regularCents > 0 && <del>{formatPrice(totalRegularCents)}</del>}
        </span>
        {freeShipping && (
          <strong className='c-tag c-tag--big-highlight'>
            livraison offerte
          </strong>
        )}
        {alma?.merchantId && (
          <AlmaWidget merchantId={alma.merchantId} cents={totalCents} />
        )}
        <div id={`widget-mdm-${id}`} style={{ marginTop: '1.5rem' }} />
      </header>

      {/* Delivery info */}
      {deliveryLabel && (
        <strong className='c-product-information__delivery c-tag c-tag--big-mark'>
          {deliveryLabel}
        </strong>
      )}
      {/* Description */}
      <div className='c-product-information__wrapper u-mb-2'>
        <p
          className='u-mb-05'
          dangerouslySetInnerHTML={{ __html: shortDescription }}
        ></p>
        <a data-target='disclosure-description' href='#product-description'>
          Lire la suite
        </a>
      </div>

      <form onSubmit={addToCart}>
        {/* Family dropdown (if applicable); clicks redirect to related product pages */}
        {family?.products?.length > 0 && (
          <div className='u-mb-3'>
            <label htmlFor='select-family'>{family.title}</label>
            <select
              id='select-family'
              className='c-select c-select--inline'
              onChange={handleFamilyRedirect}
              value={id}
            >
              {family.products.map(({ id: prodId, name: prodName }) => (
                <option key={prodId} role='link' value={prodId}>
                  {prodName}
                </option>
              ))}
            </select>
          </div>
        )}

        {/* Size guide CTA, if applicable */}
        {guide && (
          <a
            aria-haspopup='dialog'
            className='c-link c-link--icon u-mb-2'
            href={guide.url}
            onClick={openGuideDialog}
          >
            {guide.title}
          </a>
        )}

        {/* Variant selection, if applicable */}
        {variants && (
          <Variants
            {...initialVariants}
            {...variants}
            selectedId={selectedVariantId}
            onChange={setVariants}
          />
        )}

        {/* Has-a-bag info tag */}
        {jewelleryBag && (
          <div className='c-tag--container u-mb-4'>
            <mark className='c-tag c-tag--accessory'>
              Livré avec son écrin et sac à bijou
            </mark>
          </div>
        )}

        <div className='u-txt-right'>
          {/* Cart existing items for this product, and action buttons: option selection, add-to-cart proper */}
          {cartQuantity > 0 && (
            <div>
              <small>
                Déjà ajouté {cartQuantity > 1 ? `${cartQuantity} fois` : ''} à
                votre panier
              </small>
            </div>
          )}
          {options.map((option) => (
            <div key={option.id} className={optionsWrapperClasses}>
              <Option
                {...option}
                onCancel={handleOptionCancel}
                onChange={handleOptionChange}
                disabled={optionsDisabled}
                hidePrice
              />
            </div>
          ))}
          <AddToCartButton
            addableToCart={addableToCart}
            customerEmail={customerEmail}
            pending={pending}
            onCreatedAlert={onCreatedAlert}
            variantId={selectedVariantId}
            disabled={variantsAlerts.includes(selectedVariantId)}
          />
        </div>
        {flash && (
          <div className={`c-message c-message--${flash.kind}`}>
            {flash.message}
          </div>
        )}
      </form>

      {/* Actual size guide contents (triggered by earlier CTA) */}
      {guide && (
        <Dialog
          className='right-modal'
          onCancel={closeDialog}
          opened={dialog === 'guide'}
          title={guide.title}
        >
          <div
            className='u-font-sm'
            dangerouslySetInnerHTML={{ __html: guide.content }}
          />
          <span className='u-block u-mb-2@us u-mb-3@fs'>
            <a
              className='c-link c-link--icon'
              href='/page/nos-guides'
              rel='noopener noreferrer'
            >
              Découvrez l’ensemble de nos guides ici
            </a>
          </span>
          {guide.pdf && (
            <a
              className='c-btn c-btn--print'
              href={guide.pdf}
              rel='noopener noreferrer'
              target='_blank'
            >
              <svg className='c-btn__icon'>
                <use xlinkHref='#print'></use>
              </svg>
              Imprimer le guide des tailles
            </a>
          )}
        </Dialog>
      )}

      {/* Add-to-cart confirmation dialog, with related products and all */}
      <Dialog
        className='center-modal'
        onCancel={closeAddToCartDialog}
        opened={dialog === 'add-to-cart'}
        title='Cet article a été ajouté à votre panier'
      >
        <div className='c-added-to-cart'>
          <article className='o-table o-table--reverse c-added-to-cart__product'>
            <div className='o-table__cell c-added-to-cart__item'>
              <strong className='c-added-to-cart__name'>{name}</strong>
              <ins>{formatPrice(totalCents)}</ins>{' '}
              {regularCents > 0 && <del>{formatPrice(totalRegularCents)}</del>}
              {computeSummary()}
            </div>
            {htmlPicture && (
              <div
                className='o-table__cell c-added-to-cart__thumbnail'
                dangerouslySetInnerHTML={{ __html: htmlPicture }}
              />
            )}
          </article>
          <div className='c-added-to-cart__action'>
            {/* TODO: gérer l'option papier cadeau */}
            {/* <div className='c-added-to-cart__gift'>
              <input id='gift' type='checkbox' name='gift' />
              <label
                className='c-label c-label--checkbox c-label--checkbox c-label--highlight'
                htmlFor='gift'
              >
                option papier cadeau (+ 2€)
              </label>
            </div> */}
            <a
              href='#'
              className='c-btn c-btn--back'
              onClick={closeAddToCartDialog}
            >
              <svg className='c-btn__icon'>
                <use xlinkHref='#arrow'></use>
              </svg>
              Continuer mes achats
            </a>
            <a href={cart.url} className='c-btn c-btn--tertiary'>
              Voir mon panier
            </a>
          </div>
        </div>
        {relatedProductsHTML && (
          <div dangerouslySetInnerHTML={{ __html: relatedProductsHTML }} />
        )}
      </Dialog>
    </>
  )

  async function addToCart(event) {
    event?.preventDefault()
    setPending(true)

    // Post update to the server. Expected params must look like this:
    // line_item[product_options][selection][*optionId*]: *optionValue (id or text)*
    const { error, flash } = await put(addToCartURL, {
      property_set_id: selectedVariantId,
      line_item: {
        product_options: { selection: computeOptionsToParams(options) },
      },
    })
    if (error) {
      setFlash(flash)
      return
    }

    const size = cartQuantity + 1
    setCartQuantity(size)
    notifyCartBadge(size)
    setPending(false)
    setDialog('add-to-cart')
  }

  // Massage selected options to get a user readable summary
  function computeSummary() {
    const labels = formatOptionsSummary(options)
    if (labels?.length > 0) {
      return labels.map(([title, label], index) => (
        <div key={`${title}-${index}`}>
          <OptionsSummaryLabel title={title} label={label} />
          &nbsp;-&nbsp;
          {formatPrice(optionsCents + optionsTaxCents)}
        </div>
      ))
    }
  }

  function closeAddToCartDialog(event) {
    closeDialog(event)
    setOptions(initialOptions)
  }

  function closeDialog(event) {
    event?.preventDefault()
    setDialog('')
  }

  function handleFamilyRedirect({ target: { value } }) {
    const productId = Number(value)
    const { url } = family.products.find((product) => product.id === productId)
    // Do not redirect current product to itself
    if (id !== productId) {
      window.location.href = url
    }
  }

  // Reset a given option to its initial value
  function handleOptionCancel(optId) {
    const values = initialOptions.find((option) => option.id === optId).values
    const updatedOptions = options.map((option) =>
      option.id === optId ? { ...option, values } : option
    )
    setOptions(updatedOptions)
  }

  // Dynamically store filled values for expected option
  function handleOptionChange(optId, values) {
    const updatedOptions = options.map((option) =>
      option.id === optId ? { ...option, values } : option
    )
    setOptions(updatedOptions)
  }

  function openGuideDialog(event) {
    event?.preventDefault()
    setDialog('guide')
  }

  function onCreatedAlert(variantId) {
    setVariantsAlerts([...variantsAlerts, variantId])
  }
}

Description.propTypes = {
  addToCartURL: string.isRequired,
  alma: shape({
    merchantId: string,
  }).isRequired,
  authorized: bool.isRequired,
  brand: shape({
    name: string.isRequired,
    url: string.isRequired,
  }),
  cart: shape({
    cents: number.isRequired,
    quantity: number.isRequired,
    url: string.isRequired,
  }),
  customerEmail: string,
  family: shape({
    title: string.isRequired,
    products: arrayOf(
      shape({
        name: string.isRequired,
        url: string.isRequired,
      })
    ),
  }),
  guide: shape({
    content: string.isRequired,
    title: string.isRequired,
    url: string.isRequired,
  }),
  htmlPicture: string,
  id: number.isRequired,
  initialOptions: arrayOf(shape(ProductOptionPropTypes)),
  initialVariants: shape(VariantsPropTypes),
  jewelleryBag: bool.isRequired,
  name: string.isRequired,
  ratings: shape(RatingsPropTypes),
  relatedProductsHTML: string,
  sale: shape(SalePropTypes),
  shippingOfferedThreshold: number.isRequired,
  shortDescription: string.isRequired,
  stocklessSellable: bool.isRequired,
}

export default Description

function AddToCartButton({
  addableToCart,
  customerEmail,
  disabled,
  pending,
  onCreatedAlert,
  variantId,
}) {
  if (addableToCart) {
    return (
      <button
        className='c-btn c-btn--tertiary c-btn--sticky-cart u-mb-2'
        type='submit'
      >
        Ajouter au panier
      </button>
    )
  }

  if (pending) {
    return (
      <button
        className='c-btn c-btn--primary c-btn--sticky-cart u-mb-2'
        disabled
      >
        Ajout en cours…
      </button>
    )
  }

  return (
    <StockAlertButton
      disabled={disabled}
      customerEmail={customerEmail}
      onCreatedAlert={onCreatedAlert}
      variantId={variantId}
    />
  )
}

AddToCartButton.propTypes = {
  addableToCart: bool.isRequired,
  customerEmail: string,
  disabled: bool.isRequired,
  pending: bool.isRequired,
  onCreatedAlert: func.isRequired,
  variantId: number,
}
