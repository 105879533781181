import { useState } from 'react'

import Audio from './Audio'
import MainSlider from './MainSlider'
import { MAX_REGULAR_THUMBS, SliderCommonPropTypes } from './utils'
import Thumb from './Thumb'
import ThumbSlider from './ThumbSlider'
import Video from './Video'

export default function Photos({ audio, slides }) {
  const [mainSlider, setSlider] = useState(null)
  const [subSlider, setSubSlider] = useState(null)
  const [mainSlide, trackMainSlideForManualThumbs] = useState(0)

  return (
    <div className='c-glide--active'>
      <MainSlider
        currentSlideIndex={mainSlide}
        onChange={trackMainSlideForManualThumbs}
        sliderRef={setSlider}
        slides={slides}
        subSliderRef={subSlider}
      />
      {slides.length > MAX_REGULAR_THUMBS ? (
        <ThumbSlider
          mainSliderRef={mainSlider}
          sliderRef={setSubSlider}
          slides={slides}
        />
      ) : (
        <div className='c-glide__thumbnail-wrapper u-hidden@us'>
          {slides.map(({ alt, audio, video, thumb }, index) =>
            audio ? (
              <Audio
                url={audio}
                key={index}
                onClick={() => mainSlider?.slickGoTo(index)}
                thumb
              />
            ) : video ? (
              <Video
                identifier={video}
                key={index}
                onClick={() => mainSlider?.slickGoTo(index)}
                thumb
              />
            ) : (
              <Thumb
                active={mainSlide === index}
                alt={alt}
                key={index}
                {...thumb}
                onClick={() => mainSlider?.slickGoTo(index)}
              />
            )
          )}
        </div>
      )}
    </div>
  )
}

Photos.propTypes = { ...SliderCommonPropTypes }
