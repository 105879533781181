import { bool, func, string } from 'prop-types'

export default function Checkbox({
  disabled,
  id,
  label,
  name,
  onChange,
  value,
}) {
  return (
    <>
      <div className='c-option__checkbox'>
        <input
          defaultChecked={value}
          disabled={disabled}
          id='gift'
          name={name}
          onChange={handleChange}
          type='checkbox'
        />
        <label
          className='c-label c-label--checkbox c-label--checkbox c-label--highlight'
          htmlFor='gift'
        >
          {label}
        </label>
      </div>
    </>
  )

  function handleChange({ target: { checked } }) {
    onChange(checked)
  }
}

Checkbox.propTypes = {
  disabled: bool,
  id: string,
  label: string.isRequired,
  name: string,
  onChange: func,
  value: string,
}
