import { arrayOf, bool, exact, func, number, oneOf, string } from 'prop-types'

export default function Select({
  disabled,
  id,
  label,
  name,
  onChange,
  required,
  selectedValue,
  values,
  valid,
}) {
  return (
    <>
      <label htmlFor={`select-${id}`} className='c-label c-label--image'>
        {label}
      </label>
      <select
        aria-invalid={!valid}
        className='c-select'
        defaultValue={selectedValue}
        disabled={disabled}
        id={`select-${id}`}
        name={name}
        onChange={handleChange}
      >
        {values.map(({ id: valueId, label: valueLabel }) => (
          <option key={valueId} value={valueId}>
            {valueLabel}
          </option>
        ))}
      </select>
      {!valid && (
        <p className='c-message c-message--error'>
          Vous devez choisir une valeur
        </p>
      )}
    </>
  )

  function handleChange({ target: { value: selectedId } }) {
    onChange(Number(selectedId))
  }
}

Select.defaultProps = { valid: true }

Select.propTypes = {
  disabled: bool,
  id: string,
  label: string.isRequired,
  name: string.isRequired,
  onChange: func,
  required: bool,
  selectedValue: oneOf([string, number]),
  values: arrayOf(
    exact({ id: oneOf([string, number]), value: oneOf([string, number]) })
  ),
  valid: bool,
}
