import { arrayOf, func, number, string } from 'prop-types'

export default function PerPageSelect({
  className = '',
  count,
  id,
  label,
  perPage,
  perPageBasis,
  perPageList,
  setPerPage,
}) {
  return (
    <div className={`c-result ${className}`}>
      <strong className='c-result__total'>{count === 0 ? '' : label}</strong>
      {count > perPageBasis && (
        <span className='u-hidden@us'>
          <label htmlFor={id}>Résultats par page</label>
          <select
            className='c-select c-select--inline'
            defaultValue={perPage}
            id={id}
            name='per_page'
            onChange={setPerPage}
          >
            {perPageList.map((value) => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
          </select>
        </span>
      )}
    </div>
  )
}

PerPageSelect.propTypes = {
  className: string,
  count: number.isRequired,
  id: string.isRequired,
  label: string.isRequired,
  perPage: number.isRequired,
  perPageBasis: number.isRequired,
  perPageList: arrayOf(number),
  setPerPage: func.isRequired,
}
