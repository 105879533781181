import { bool, func, string } from 'prop-types'
import React from 'react'

import { FiltersPropTypes } from '../../shared/prop-types'

const Facet = ({
  activeFilters = [],
  disabled,
  filters,
  id,
  onClick,
  onFilterDelete,
  onFilterSelect,
  prefix = '',
  title,
}) => {
  return (
    <fieldset className='c-filter__category'>
      <legend className='c-filter__legend'>{title}</legend>

      {filters.map(({ label, value }) => (
        <React.Fragment key={`${id}-${value}`}>
          <input
            checked={isFilterActive(value)}
            disabled={disabled}
            id={`${prefix}-${id}-${value}`}
            onChange={() => handleFilter({ label, value })}
            type='checkbox'
          />
          <label
            className='c-label c-label--checkbox'
            disabled={disabled}
            htmlFor={`${prefix}-${id}-${value}`}
          >
            {label}
          </label>
        </React.Fragment>
      ))}
    </fieldset>
  )

  // Manage filter selection or removal
  function handleFilter(filter) {
    if (isFilterActive(filter.value)) {
      onFilterDelete({ ...filter, id })
    } else {
      onFilterSelect({ ...filter, id })
    }
  }

  // Check if the given filter is active
  function isFilterActive(filterValue) {
    return activeFilters.some(({ value }) => value === filterValue)
  }
}

Facet.propTypes = {
  activeFilters: FiltersPropTypes,
  disabled: bool,
  filters: FiltersPropTypes.isRequired,
  id: string.isRequired,
  onClick: func.isRequired,
  onFilterDelete: func.isRequired,
  onFilterSelect: func.isRequired,
  prefix: string,
  title: string.isRequired,
}

export default Facet
