import { RatingsPropTypes } from '../../shared/prop-types'

const Ratings = ({ rating, reviewsCount }) => {
  if (reviewsCount <= 0) {
    return null
  }

  const customClass = `c-rating__stars--${Math.round(rating * 10)}`
  const reviewsLinkLabel = reviewsCount === 1 ? 'Voir l’avis' : 'Voir les avis'

  return (
    <dl className='c-rating'>
      <dt className='u-visually-hidden'>Note attribuée</dt>
      <dd className={`c-rating__stars ${customClass}`}>
        <span className='u-visually-hidden'>{rating}/5</span>
      </dd>
      <dt className='u-visually-hidden'>Nombre d’avis</dt>
      <dd>
        <span className='c-rating__review'>{reviewsCount} avis</span>
        <a href='#anchor-tratings' onClick={scrollToRatings}>
          {reviewsLinkLabel}
        </a>
      </dd>
    </dl>
  )

  function scrollToRatings() {
    const anchor = document.getElementById('anchor-tratings')
    // First, trigger tab click to display its content
    anchor?.click()
    // Then scroll to it
    anchor?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }
}

Ratings.propTypes = RatingsPropTypes

export default Ratings
