import { arrayOf, bool, number, shape, string } from 'prop-types'
import { useState } from 'react'

import {
  AddressPropTypes,
  CarrierPropTypes,
  PriceDetailsBaseProps,
} from '../../../shared/prop-types'
import { camelToSnakeCaseObjectKeys } from '../../../../../shared/js/formatters'
import CarrierBlock from './CarrierBlock'
import Dialog from '../../shared/Dialog'
import EventDateAndComment from './EventDateAndComment'
import FormSetup from '../../../../../shared/js/components/FormSetup'
import PriceDetails from '../shared/PriceDetails'
import { put } from '../../../../../shared/js/json-fetch'

const CarrierChoice = ({
  addressesURL,
  billingAddress,
  carriers,
  customFields,
  mobileExcludedCarriers,
  nextStepURL,
  selectedCarrierId,
  selectedPickupPointCode: initialPickupPointCode,
  shippingAddress,
  termsOfUseHTML,
  termsOfUseURL,
  url,
  // PriceDetails props
  totalCents,
  ...props
}) => {
  // DEV NOTE: this has been commented after Bastiens request to not
  // default/cheapest carrier.
  // if (selectedCarrierId == null) {
  //   selectedCarrierId = carriers[0]?.id || {}
  //   // We must ensure order is also updated before step validation/redirection.
  //   handleCarrierChange({ carrierId: selectedCarrierId })
  // }
  // When address has changed, previously selected carrier may not exist anymore
  // in the list. We must therefore enforce carrier selection.
  // let initialCarrier = carriers.find(({ id }) => id === selectedCarrierId)
  // if (!initialCarrier) {
  //   selectedCarrierId = carriers[0]?.id
  //   initialCarrier = carriers.find(({ id }) => id === selectedCarrierId)
  // }
  const initialCarrier = carriers.find(({ id }) => id === selectedCarrierId)

  const [validationError, setValidationError] = useState('')
  const [error, setError] = useState('')
  const [selectedCarrier, setSelectedCarrier] = useState(initialCarrier)
  const [selectedPickupPointCode, setSelectedPickupPointCode] = useState(
    initialPickupPointCode
  )
  const selectedPickupPoint =
    selectedPickupPointCode && selectedCarrier.pickupPoints
      ? selectedCarrier.pickupPoints.find(
          ({ code }) => selectedPickupPointCode === code
        )
      : undefined
  const [dialogOpen, setDialogOpen] = useState(false)
  const [termsOfUseChecked, setTermsOfUseChecked] = useState(false)
  const [termsOfUseHighlight, setTermsOfUseHighlight] = useState(false)

  const termsOfUseClassName = `c-label c-label--checkbox c-label__centered${
    termsOfUseHighlight ? ' highlighted' : ''
  }`

  const validChoice =
    selectedCarrier &&
    (!selectedCarrier.pickupPoints || selectedPickupPointCode)
  const nextStepEnabled = termsOfUseChecked && validChoice

  return (
    <>
      <div className='o-layout__item-9-cols o-layout__shrink-2'>
        <span className='c-counter'>
          Adresse de livraison
          <a className='c-counter__link' href={addressesURL}>
            modifier
          </a>
        </span>
        <h1 className='c-counter'>Mode de livraison</h1>
        <form className='c-form c-form' method='POST'>
          <FormSetup method='PUT' />
          {error && (
            <div className={'c-message c-message--error u-mb-2'}>{error}</div>
          )}
          {mobileExcludedCarriers && (
            <div className='c-message.c-message--help'>
              Certains modes de livraison ne vous sont pas proposés car
              <strong>
                votre adresse de livraison ne renseigne pas de téléphone mobile.
              </strong>
            </div>
          )}
          {carriers.map((carrier) => (
            <CarrierBlock
              {...carrier}
              key={carrier.id}
              billingAddress={billingAddress}
              onChange={handleCarrierChange}
              selected={selectedCarrier?.id === carrier.id}
              selectedPickupPointCode={selectedPickupPointCode}
              shippingAddress={shippingAddress}
            />
          ))}
          <EventDateAndComment {...customFields} />
          <div className='c-form__block c-form__block--fieldset c-form__next-step'>
            <div className='o-layout o-layout--center-x u-mb-4'>
              <input
                checked={termsOfUseChecked}
                id='terms-of-use'
                name='terms_of_use'
                onChange={toggleTermsOfUse}
                type='checkbox'
              />
              <label htmlFor='terms-of-use' className={termsOfUseClassName}>
                J’accepte sans réserve&nbsp;
                <a href={termsOfUseURL} onClick={openDialog}>
                  les conditions générales de vente
                </a>
              </label>
            </div>
            {termsOfUseChecked && !validChoice && (
              <div className='c-message c-message--error u-mb-2'>
                (Vous devez choisir un transporteur)
              </div>
            )}
            <a
              href={nextStepURL}
              className='c-btn c-btn--tertiary'
              onClick={highlightTermsOfUse}
              disabled={!nextStepEnabled}
            >
              Passer au paiement
            </a>

            {validationError && (
              <div className='c-message c-message--error u-mb-2'>
                {validationError}
              </div>
            )}
          </div>
        </form>
        <span className='c-counter'>Paiement</span>
      </div>
      <div className='o-layout__item-3-cols'>
        <PriceDetails
          {...props}
          deliveryLabel={selectedCarrier?.shippingLabel || props.deliveryLabel}
          actualShippingAddress={shippingAddress}
          selectedPickupPoint={selectedPickupPoint}
          shipping={{
            name: selectedCarrier?.name,
            cents: selectedCarrier?.cents,
          }}
          totalCents={totalCents + (selectedCarrier?.cents || 0)}
        />
      </div>
      <Dialog
        className='right-modal'
        onCancel={closeDialog}
        opened={dialogOpen}
        title='Conditions Générales de Vente'
      >
        <div dangerouslySetInnerHTML={{ __html: termsOfUseHTML }} />
        <div className='right-action-modal__btn'>
          <button
            className='c-btn c-btn--fill'
            type='reset'
            onClick={closeDialog}
          >
            Annuler
          </button>
          <button
            className='c-btn c-btn--primary'
            onClick={closeAndAcceptTermsOfUse}
          >
            J’accepte les <abbr title='Conditions Générales de Vente'>CGV</abbr>
          </button>
        </div>
      </Dialog>
    </>
  )

  // Send selected carrier ID and pickup point ID to the server
  async function handleCarrierChange({
    carrierId,
    // Use previously set code
    pickupPointCode = selectedPickupPointCode,
    pickupPointStructuredAddress,
  }) {
    const carrier = carriers.find(({ id }) => id === carrierId)
    // Ensure selected pickup point code is linked to current carrier.
    // Otherwise set it to null.
    if (carrier.pickupPoints && pickupPointCode) {
      pickupPointCode = carrier.pickupPoints.find(
        ({ code }) => pickupPointCode === code
      )?.code
    }

    if (!carrier.pickupPoints || pickupPointCode) {
      // If carrier is expecting a pickup point, wait for it to be selected
      const { error, flash } = await put(url, {
        ...camelToSnakeCaseObjectKeys({
          carrierId: carrier.id,
          pickupPointCode,
          pickupPointStructuredAddress,
        }),
      })
      setValidationError('')
      if (error) {
        setError(flash.message)
        return
      }
    }

    setSelectedCarrier(carrier)
    if (pickupPointCode && pickupPointCode !== selectedPickupPointCode) {
      setSelectedPickupPointCode(pickupPointCode)
    } else if (!carrier.pickupPoints) {
      // Remove pickup point selection when switching to a carrier that does not
      // have pickup points.
      setSelectedPickupPointCode(null)
    }
  }

  function highlightTermsOfUse(event) {
    if (!termsOfUseChecked) {
      event.preventDefault()
      setValidationError('')
      !termsOfUseHighlight && setTermsOfUseHighlight(true)
    }
    if (!validChoice) {
      event.preventDefault()
      setValidationError('Auriez-vous oublié de choisir un point relais ?')
    }
  }

  function closeAndAcceptTermsOfUse() {
    setDialogOpen(false)
    setTermsOfUseChecked(true)
    setTermsOfUseHighlight(false)
  }

  function closeDialog() {
    setDialogOpen(false)
  }

  function openDialog(event) {
    event.preventDefault()
    setDialogOpen(true)
  }

  function toggleTermsOfUse() {
    setTermsOfUseChecked(!termsOfUseChecked)
    if (!termsOfUseChecked && termsOfUseHighlight) {
      setTermsOfUseHighlight(false)
    }
  }
}

CarrierChoice.propTypes = {
  ...PriceDetailsBaseProps,
  addressesURL: string.isRequired,
  billingAddress: shape(AddressPropTypes),
  carriers: arrayOf(shape(CarrierPropTypes)),
  customFields: shape(EventDateAndComment.propTypes),
  mobileExcludedCarriers: bool,
  selectedCarrierId: number,
  selectedPickupPointCode: string,
  shippingAddress: shape(AddressPropTypes).isRequired,
  termsOfUseHTML: string.isRequired,
  termsOfUseURL: string.isRequired,
  url: string.isRequired,
}

export default CarrierChoice
