import { bool, func, shape, string } from 'prop-types'
import { useState } from 'react'

import { AddressPropTypes, CarrierPropTypes } from '../../../shared/prop-types'
import Dialog from '../../shared/Dialog'
import ErrorBoundary from '../../../../../shared/js/components/ErrorBoundary'
import { formatPrice } from '../../../../../shared/js/formatters'
import PickupPoints from './PickupPoints'

const CarrierBlock = ({
  billingAddress,
  cents,
  id,
  name,
  onChange,
  pickupPoints,
  selected,
  selectedPickupPointCode,
  shippingLabel,
  shippingAddress,
}) => {
  const inputId = `carrier-${id}`
  const [dialogOpen, setDialogOpen] = useState(false)

  const selectedPoint =
    selectedPickupPointCode &&
    pickupPoints?.length > 0 &&
    pickupPoints.find(({ code }) => code === selectedPickupPointCode)

  return (
    <ErrorBoundary
      fallBackComponent={() => (
        <div className='c-form__block c-form__block--radio'>
          <div className='c-message c-message--error'>
            Nous sommes désolés, le transporteur <strong>{name}</strong>{' '}
            rencontre un problème et ne peut vous être proposé
          </div>
        </div>
      )}
    >
      <div className='c-form__block c-form__block--radio'>
        <input
          defaultChecked={selected}
          id={inputId}
          name='carrier_id'
          onClick={() => onChange({ carrierId: id })}
          type='radio'
          value={id}
        />
        <label
          className='c-label c-form__label c-label--radio c-label--highlight'
          htmlFor={inputId}
        >
          {`${name} `}
          <span className='c-form__price'>
            {cents > 0 ? formatPrice(cents) : 'Gratuit'}
          </span>{' '}
          {shippingLabel && (
            <span className='c-form__delivery-date'>{shippingLabel}</span>
          )}
        </label>

        <div className='c-form__details'>
          {selected && pickupPoints?.length > 0 ? (
            <>
              {selectedPoint ? (
                <div className='c-address c-address--box'>
                  <p className='u-mb-2@us'>
                    <strong className='c-address__label'>Relais</strong>
                    <span className='c-address__name'>
                      {selectedPoint.name}
                    </span>
                    <br />
                    {selectedPoint.address}
                  </p>
                </div>
              ) : (
                <div className='c-message c-message--help'>
                  Veuillez sélectionner un point relais
                </div>
              )}
              <div className='u-txt-center u-mb-1 u-hidden@fs c-form__event-btn'>
                <button className='c-btn c-btn--secondary' onClick={openDialog}>
                  {selectedPoint
                    ? 'Changer de point relais'
                    : 'Choisir un point relais'}
                </button>
              </div>

              <div className='select-relay-point u-hidden@us'>
                <PickupPoints
                  onChange={handlePickupPointChange}
                  pickupPoints={pickupPoints}
                  selectedCode={selectedPickupPointCode}
                />
              </div>
            </>
          ) : (
            <>
              <Address {...shippingAddress} label='Adresse de livraison' />
              {billingAddress && billingAddress.id !== shippingAddress.id && (
                <Address {...billingAddress} label='Adresse de facturation' />
              )}
            </>
          )}
        </div>
      </div>
      {pickupPoints?.length > 0 && (
        <Dialog
          className='right-action-modal'
          onCancel={closeDialog}
          opened={dialogOpen}
          title={`${selectedPoint ? 'Changer de' : 'Choisir un'} point relais`}
        >
          <PickupPoints
            onCancel={closeDialog}
            onChange={handlePickupPointChange}
            pickupPoints={pickupPoints}
            selectedCode={selectedPickupPointCode}
            validateOnSelect
          />
        </Dialog>
      )}
    </ErrorBoundary>
  )

  function closeDialog() {
    setDialogOpen(false)
  }

  // `onChange` prop is used for both actions:
  // - carrier selection
  // - pickup point selection
  function handlePickupPointChange({
    code: pickupPointCode,
    structuredAddress: pickupPointStructuredAddress,
  }) {
    onChange({
      carrierId: id,
      pickupPointCode,
      pickupPointStructuredAddress,
    })
    closeDialog()
  }

  function openDialog(event) {
    event.preventDefault()
    setDialogOpen(true)
  }
}

CarrierBlock.propTypes = {
  ...CarrierPropTypes,
  billingAddress: shape(AddressPropTypes),
  onChange: func.isRequired,
  selected: bool,
  selectedCode: string,
  shippingAddress: shape(AddressPropTypes).isRequired,
}

export default CarrierBlock

const Address = ({
  address,
  city,
  company,
  complement,
  country,
  firstName,
  lastName,
  mobilePhone,
  label,
  zipCode,
}) => {
  return (
    <p className='c-address c-address--box'>
      <strong className='c-address__label'>{label}</strong>
      <span className='c-address__name'>
        {firstName} {lastName}
      </span>
      <br />
      {mobilePhone}
      <br />
      {company && (
        <>
          {company}
          <br />
        </>
      )}
      {address} - {zipCode} {city}
      <br />
      {complement && (
        <>
          {complement}
          <br />
        </>
      )}
      {country}
    </p>
  )
}

Address.propTypes = {
  ...AddressPropTypes,
  label: string.isRequired,
}
