import { string } from 'prop-types'
import { useState } from 'react'

import Dialog from '../shared/Dialog'

const SearchDialogLink = ({ html, url }) => {
  const [opened, setOpened] = useState(false)

  return (
    <>
      <button
        className='c-shortcut__link'
        type='button'
        onClick={toggleDialog}
        aria-haspopup='dialog'
      >
        <svg aria-hidden='' className='c-shortcut__icon'>
          <use xlinkHref='#search'></use>
        </svg>
        Recherche
      </button>

      <Dialog
        className='top-modal'
        initialFocus='#search-input'
        onCancel={toggleDialog}
        opened={opened}
        title='Recherche'
      >
        <section>
          <form
            id='search'
            className='u-mb-4@us u-mb-8@fs'
            role='search'
            action={url}
            method='get'
          >
            <div className='u-position'>
              <label className='u-visually-hidden' htmlFor='search-input'>
                Recherche par mots-clés (exemple: médaille vierge,
                saint-christophe, ange…)
              </label>
              <input
                type='search'
                name='search'
                id='search-input'
                className='form-control'
                placeholder='médaille vierge, saint-christophe, ange…'
              />
              <button className='c-helper c-helper--search' type='submit'>
                <span className='u-visually-hidden'>Rechercher</span>
              </button>
            </div>
          </form>
          {html && (
            <aside
              className='o-wrapper o-wrapper--no-space c-glide / js-glide-um'
              dangerouslySetInnerHTML={{ __html: html }}
            />
          )}
        </section>
      </Dialog>
    </>
  )

  function toggleDialog(event) {
    event.preventDefault()
    setOpened(!opened)
  }
}

SearchDialogLink.propTypes = {
  html: string,
  url: string.isRequired,
}

export default SearchDialogLink
