import { func, string } from 'prop-types'
import { useState } from 'react'

import { post as jsonPost } from '../../../../../shared/js/json-fetch'
import useTimeout from '../../../../../shared/js/use-timeout'

const DiscountForm = ({ onChange, post = jsonPost, url }) => {
  const [discountsHidden, setDiscountsHidden] = useState(true)
  const [flash, setFlash] = useState(null)
  const [disabled, setDisabled] = useState(false)
  const [ticketCode, setTicketCode] = useState('')

  // Remove flash messages after 5 seconds
  useTimeout(() => setFlash(null), 5000)

  return (
    <div className='c-voucher'>
      <button
        className='c-disclosure c-disclosure--voucher'
        aria-controls='disclosure-discount'
        aria-expanded={discountsHidden}
        onClick={toggleDiscount}
        type='button'
      >
        Ajouter un code promo
      </button>
      <div
        id='disclosure-discount'
        className='c-voucher__content'
        hidden={discountsHidden}
      >
        <label className='u-visually-hidden' htmlFor='voucher'>
          Code promotionnel
        </label>
        <div className='o-layout o-layout--keep@us'>
          <input
            aria-required='true'
            disabled={disabled}
            className='c-voucher__input'
            id='ticket-code'
            name='ticket_code'
            onChange={handleTicketCodeChange}
            placeholder='Code promo'
            type='text'
            value={ticketCode}
          />
          <button
            className='c-voucher__btn c-btn c-btn--tertiary'
            onClick={applyTicketCode}
            disabled={disabled || ticketCode.trim() === ''}
            data-testid='apply-ticket-code'
          >
            Valider
          </button>
        </div>
        {flash && (
          <div className={`c-message c-message--${flash.kind}`}>
            {flash.message}
          </div>
        )}
      </div>
    </div>
  )

  // Request server then print applied discount
  // or error message when not applicatble
  async function applyTicketCode(event) {
    event.preventDefault()
    setDisabled(true)

    // Send data to server
    const { data, flash } = await post(url, {
      ticket_code: ticketCode,
    })

    setDisabled(false)
    if (flash) {
      setFlash(flash)
    }
    if (data) {
      setTicketCode('')
      onChange(data)
    }
  }

  function handleTicketCodeChange({ target: { value } }) {
    setTicketCode(value)
  }

  function toggleDiscount() {
    setDiscountsHidden(!discountsHidden)
  }
}

DiscountForm.propTypes = {
  onChange: func.isRequired,
  url: string.isRequired,
  // This one is for testing purpose.  Post function is injected from json-fetch
  // module using default params
  post: func,
}

export default DiscountForm
