import { string } from 'prop-types'
import { useState } from 'react'

const MAX_LENGTH = 350

export default function TruncatedText({ previewText, htmlText }) {
  const hideExpected = htmlText.length > MAX_LENGTH
  const [hidden, setHidden] = useState(hideExpected)

  return (
    <div className='o-layout c-cover c-cover__bottom u-background-light'>
      <div className='c-cover__content'>
        <div
          className='c-cover__description'
          dangerouslySetInnerHTML={{
            __html: hidden ? previewText : htmlText,
          }}
        />
        {hideExpected && (
          <a className='c-cover__more' onClick={() => setHidden(!hidden)}>
            {hidden ? 'Lire la suite' : 'Masquer le texte'}
          </a>
        )}
      </div>
    </div>
  )
}

TruncatedText.propTypes = {
  previewText: string.isRequired,
  htmlText: string.isRequired,
}
