import { bool, number, oneOf, string } from 'prop-types'

import { numberToCurrency } from '../../../../../shared/js/number-helpers'
import { PRICING_MODES } from '../../../../../shared/js/formatters'

export default function PriceFormatter({
  cents,
  className,
  mode,
  stripUnit,
  unit,
}) {
  unit ||= stripUnit ? '€' : ' €'
  const value = cents / 100.0
  const precision = cents % 100 !== 0 ? 2 : 0

  const basis = numberToCurrency(value, {
    unit,
    format: '%n%u',
    precision,
    delimiter: ' ',
  })
  if (!mode) {
    return className ? <ins className={className}>{basis}</ins> : basis
  }

  const [shortLabel, longLabel] = PRICING_MODES[mode] || []
  if (!shortLabel || !longLabel) {
    return className ? <ins className={className}>{basis}</ins> : basis
  }

  const unitLabel = <abbr title={longLabel}>{shortLabel}</abbr>

  return className ? (
    <ins className={className}>
      {basis}
      {unitLabel}
    </ins>
  ) : (
    <>
      {basis}
      {unitLabel}
    </>
  )
}

PriceFormatter.propTypes = {
  cents: number.isRequired,
  className: string,
  mode: oneOf(Object.keys(PRICING_MODES)),
  stripUnit: bool,
  unit: string,
}
