import { bool, func, number, string } from 'prop-types'
import { useState } from 'react'

import {
  ActiveFiltersPropTypes,
  FacetsPropTypes,
} from '../../shared/prop-types'
import Facet from './Facet'

const REGEX_NUMERIC_VALUE = /^(\d+(?:\.\d+)?)(?:([mcd]?m|gr?)\.?)?$/i

const SIZE_FACTORS = { mm: 1, cm: 10, dm: 100, m: 1000 }

export function compareNumeric(a, b) {
  let [, aSize, aUnit] = REGEX_NUMERIC_VALUE.exec(a)
  let [, bSize, bUnit] = REGEX_NUMERIC_VALUE.exec(b)
  aSize = parseFloat(aSize) * (SIZE_FACTORS[aUnit] || 1)
  bSize = parseFloat(bSize) * (SIZE_FACTORS[bUnit] || 1)
  return Math.sign(aSize - bSize)
}

const SORTER = new Intl.Collator('fr-FR', {
  numeric: true,
  ignorePunctuation: true,
})

// TODO: gérer les filters par tranches tarifaires
const Filters = ({
  activeFilters,
  activeFiltersCount,
  deleteFilter,
  disabled,
  facets,
  prefix,
  selectFilter,
}) => {
  const sortedFacets = facets.map((facet) => {
    if (facet.unsorted) {
      return facet
    }

    // Separate numeric from non numeric filters to apply the right sorting method
    const numericFilters = facet.filters
      .filter(({ label }) => REGEX_NUMERIC_VALUE.test(label))
      .sort(({ label: label1 }, { label: label2 }) =>
        compareNumeric(label1, label2)
      )

    const nonNumericFilters = facet.filters
      .filter((filter) => !numericFilters.includes(filter))
      .sort(({ label: label1 }, { label: label2 }) =>
        SORTER.compare(label1, label2)
      )

    return {
      ...facet,
      filters: [...numericFilters, ...nonNumericFilters],
    }
  })

  const [expanded, setExpanded] = useState('')

  return (
    <form className='c-filter' action='#'>
      {sortedFacets.map(({ id, paramKey, title, filters }) => (
        <Facet
          activeFilters={activeFilters[id]}
          disabled={disabled}
          expanded={expanded === id}
          filters={filters}
          id={id}
          key={id}
          onClick={toggleFacet}
          onFilterDelete={deleteFilter}
          onFilterSelect={selectFilter}
          paramKey={paramKey}
          prefix={prefix}
          title={title}
        />
      ))}
    </form>
  )

  function toggleFacet(id) {
    setExpanded(expanded === id ? '' : id)
  }
}

Filters.propTypes = {
  activeFilters: ActiveFiltersPropTypes.isRequired,
  activeFiltersCount: number.isRequired,
  deleteFilter: func.isRequired,
  disabled: bool,
  facets: FacetsPropTypes.isRequired,
  prefix: string,
  selectFilter: func.isRequired,
}

export default Filters
