import { arrayOf, number, shape, string } from 'prop-types'
import React, { useState } from 'react'

import AddressesBlock from './AddressesBlock'
import {
  AddressPropTypes,
  CountriesPropTypes,
  PriceDetailsBaseProps,
} from '../../../shared/prop-types'
import FormSetup from '../../../../../shared/js/components/FormSetup'
import GiftCardComment from '../shared/GiftCardComment'
import PriceDetails from '../shared/PriceDetails'

const AddressesChoice = ({
  addAddressURL,
  addresses: initialAddresses = [],
  // Existing addresses
  billingAddressId: initialBillingAddressId,
  shippingAddressId: initialShippingAddressId,
  countries,
  // Pre-fill customer infos on address creation
  civility,
  firstName,
  lastName,
  // Giftcard
  giftCards,
  giftCardId: initialGiftCardId,
  giftCardComment: initialGiftCardComment = '',
  // PriceDetails props
  ...props
}) => {
  const [addresses, setAddresses] = useState(initialAddresses)
  const [sameAddress, setSameAddress] = useState(
    initialBillingAddressId === undefined ||
      initialShippingAddressId === initialBillingAddressId
  )
  const [shippingAddressId, setShippingAddressId] = useState(
    initialShippingAddressId || addresses[0]?.id
  )
  const [billingAddressId, setBillingAddressId] = useState(
    initialBillingAddressId
  )

  const shippingAddresses = addresses

  // *If* we display distinct billing addresses, they should *not* include the
  // currently-selected shipping address.
  const billingAddresses = addresses.filter(
    ({ id }) => id !== shippingAddressId
  )

  const nextStepEnabled = shippingAddressId && (sameAddress || billingAddressId)

  // Giftcard
  const [{ giftCardComment, giftCardId }, setValues] = useState({
    giftCardId: initialGiftCardId,
    giftCardComment: initialGiftCardComment,
  })

  return (
    <>
      <div className='o-layout__item-9-cols o-layout__shrink-2'>
        <h1 className='c-counter'>Adresse de livraison</h1>

        <form className='c-form c-form--delivery' method='POST'>
          <FormSetup method='PUT' />
          <fieldset className='c-form__block c-form__block--fieldset'>
            <legend className='o-container-6 c-form__legend'>
              Adresse de livraison
            </legend>
            <AddressesBlock
              addAddressURL={addAddressURL}
              addresses={shippingAddresses}
              civility={civility}
              countries={countries}
              firstName={firstName}
              lastName={lastName}
              kind='shipping'
              onCreateOrUpdate={handleCreateorUpdate}
              onSelect={handleSelect}
              selectedId={shippingAddressId}
            />
          </fieldset>

          <fieldset className='c-form__block c-form__block--fieldset'>
            <legend className='o-container-6 c-form__legend'>
              Adresse de facturation
            </legend>
            <div className='o-container-6 c-form__same-as'>
              <input
                aria-controls='billing-address-wrapper'
                aria-expanded={!sameAddress}
                checked={sameAddress}
                id='sameAddress'
                name='same-address'
                onChange={toggleSameAddress}
                role='button'
                type='checkbox'
              />
              <label
                className='c-label c-label--checkbox c-label--highlight'
                htmlFor='sameAddress'
              >
                Utiliser la même adresse que celle de livraison
              </label>
            </div>

            {sameAddress ? (
              <input
                type='hidden'
                name='order[billing_address_id]'
                value={shippingAddressId}
              />
            ) : (
              <div id='billing-address-wrapper'>
                <AddressesBlock
                  addAddressURL={addAddressURL}
                  addresses={billingAddresses}
                  civility={civility}
                  countries={countries}
                  firstName={firstName}
                  lastName={lastName}
                  kind='billing'
                  onCreateOrUpdate={handleCreateorUpdate}
                  onSelect={handleSelect}
                  selectedId={billingAddressId}
                />
              </div>
            )}
          </fieldset>
          <fieldset className='c-form__block c-form__block--fieldset'>
            <legend className='o-container-6 c-form__legend'>
              Message de vœux
            </legend>
            <div className='o-container-6'>
              <p className='c-form__add-message'>
                Ce message sera écrit sur le bon de livraison et nous allons
                aussi vous joindre une carte "Maison de la Médaille" à remplir à
                la main.
              </p>

              <GiftCardComment
                comment={giftCardComment}
                onChange={handleValueChange}
              />
            </div>
            {/* {giftCards?.length > 0 && (
              <>
                <p className='o-container-6 c-form__legend'>
                  Choisissez le dessin pour encadrer votre message
                </p>
                <div className='o-layout o-layout--wrap o-layout--center-x o-container-6 c-gift-cards'>
                  {giftCards.map(({ id, imgURL }) => (
                    <React.Fragment key={id}>
                      <input
                        checked={id === giftCardId}
                        id={`git-card-${id}`}
                        name='order[gift_card_id]'
                        type='radio'
                        value={id}
                        onChange={handleValueChange}
                      />
                      <label
                        className='c-label c-label--image c-label--image-square'
                        htmlFor={`git-card-${id}`}
                      >
                        <img className='c-label__square' src={imgURL} />
                      </label>
                    </React.Fragment>
                  ))}
                </div>
              </>
            )} */}
          </fieldset>
          <div className='c-form__block c-form__block--fieldset c-form__next-step'>
            <button
              name='button'
              type='submit'
              className='c-btn c-btn--tertiary'
              disabled={!nextStepEnabled}
            >
              Passer au mode de livraison
            </button>
          </div>
        </form>
        <span className='c-counter'>Mode de livraison</span>
        <span className='c-counter'>Mode de paiement</span>
      </div>
      <div className='o-layout__item-3-cols'>
        <PriceDetails {...props} />
      </div>
    </>
  )

  // Update list and select created address
  function handleCreateorUpdate(address, kind) {
    // Empty address when address creation is pending
    if (address === undefined) {
      handleSelect(undefined, kind)
      return
    }

    // Prevent address duplication on update while filtering by address id
    if (addresses.find(({ id }) => address.id === id)) {
      setAddresses(
        addresses.map((current) =>
          address.id === current.id ? address : current
        )
      )
    } else {
      // Add new address to list, force selection
      setAddresses([address, ...addresses])
      handleSelect(address.id, kind)
    }
  }

  function handleSelect(id, kind) {
    if (kind === 'billing') {
      setBillingAddressId(id)
    } else {
      setShippingAddressId(id)
    }
  }

  function handleValueChange({ target: { name, value } }) {
    const camelizedName =
      name === 'gift_card_comment' ? 'giftCardComment' : 'giftCardId'
    setValues({
      giftCardComment,
      giftCardId,
      [camelizedName]: camelizedName === 'giftCardId' ? Number(value) : value,
    })
  }

  function toggleSameAddress() {
    const newSameAddress = !sameAddress
    setSameAddress(newSameAddress)

    // If customer asks for a different billing address, then set it to first
    // available address
    if (!newSameAddress && !billingAddressId && billingAddresses.length > 0) {
      setBillingAddressId(
        billingAddresses.find(({ id }) => id !== shippingAddressId)
      )
    }
  }
}

AddressesChoice.propTypes = {
  ...PriceDetailsBaseProps,
  addAddressURL: string.isRequired,
  addresses: arrayOf(shape(AddressPropTypes)),
  billingAddressId: number,
  civility: string.isRequired,
  countries: CountriesPropTypes,
  firstName: string.isRequired,
  lastName: string.isRequired,
  shippingAddressId: number,
  // Gift card
  giftCardComment: string,
  giftCardId: number,
  giftCards: arrayOf(
    shape({
      id: number.isRequired,
      imgURL: string.isRequired,
    })
  ),
}

export default AddressesChoice
