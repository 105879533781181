import { func, string } from 'prop-types'

export default function SortBySelect({ id, onChange, sortBy }) {
  return (
    <div className='o-table__cell'>
      <label htmlFor={id} className='u-visually-hidden'>
        Trier
      </label>
      <select
        id={id}
        className='c-select'
        defaultValue={sortBy}
        onChange={onChange}
      >
        <option value='' hidden>
          Trier
        </option>
        <option value='price-asc'>Du - cher au + cher</option>
        <option value='price-desc'>Du + cher au - cher</option>
        <option value='page_views-desc'>Top ventes</option>
        <option value='name-asc'>Par nom</option>
        <option value='created_at-desc'>Par nouveauté</option>
      </select>
    </div>
  )
}

SortBySelect.propTypes = {
  id: string.isRequired,
  onChange: func.isRequired,
  sortBy: string.isRequired,
}
