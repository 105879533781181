import { bool, string } from 'prop-types'
import { useState } from 'react'

import Checkbox from '../shared/Checkbox'
import Dialog from '../shared/Dialog'
import { post } from '../../../../shared/js/json-fetch'
import Select from '../shared/Select'
import TextField from '../shared/TextField'

const RATINGS = [
  { id: 0, label: '' },
  { id: 1, label: 1 },
  { id: 2, label: 2 },
  { id: 3, label: 3 },
  { id: 4, label: 4 },
  { id: 5, label: 5 },
]

export default function ReviewFormDialog({
  askForIdentifierDisplay,
  noReviews,
  url,
}) {
  const [active, setActive] = useState(false)
  const [flash, setFlash] = useState({})
  const [review, setReview] = useState('')
  const [showIdentifier, setShowIdentifier] = useState(true)
  const [rating, setRating] = useState(null)
  const [pending, setPending] = useState(false)
  const [acknowledged, setAcknowledged] = useState(false)

  const flashExtraClassName = flash?.kind === 'error' ? 'c-message--error' : ''

  if (acknowledged) {
    return (
      <p>Votre avis a bien été pris en compte et sera traité prochainement.</p>
    )
  }

  return (
    <>
      {noReviews && <p>Aucun avis pour ce produit.</p>}
      <button className='c-btn c-btn--primary' onClick={openDialog}>
        Donnez-nous votre avis
      </button>
      <Dialog
        className='right-action-modal'
        onCancel={closeDialog}
        opened={active}
        title='Donnez-nous votre avis'
      >
        <form className='right-action-modal__separator' onSubmit={handleSubmit}>
          {flash && (
            <p className={`c-message ${flashExtraClassName}`}>
              {flash.message}
            </p>
          )}
          <div>
            <Select
              errorMessage='Vous devez donner une note'
              name='review[rating]'
              id='rating'
              label='Note'
              onChange={setRating}
              selectedValue={rating}
              values={RATINGS}
              required
            />
            <TextField
              errorMessage='Vous devez renseigner votre avis'
              name='review[review]'
              id='review'
              label='Avis'
              onChange={setReview}
              value={review}
              type='textarea'
              rows={3}
              required
            />
            {askForIdentifierDisplay && (
              <Checkbox
                label='Afficher mon nom avec l’avis'
                name='review[showIdentifier]'
                onChange={setShowIdentifier}
                value={showIdentifier}
              />
            )}
          </div>
          <div className='right-action-modal__btn'>
            <button
              className='c-btn c-btn--secondary'
              type='button'
              onClick={closeDialog}
              disabled={pending}
            >
              Annuler
            </button>
            <button
              name='button'
              type='submit'
              className='c-btn c-btn--primary'
              disabled={!review || !rating || pending}
            >
              Envoyer mon avis
            </button>
          </div>
        </form>
      </Dialog>
    </>
  )

  function closeDialog() {
    setActive(false)
    setFlash(null)
    setPending(false)
  }

  async function handleSubmit(event) {
    event.preventDefault()
    setPending(true)
    setFlash({ kind: 'info', message: 'Traitement en cours…' })

    try {
      const { flash } = await post(url, { review: { review, rating } })

      setPending(false)

      if (flash) {
        setFlash(flash)
        return
      }

      setAcknowledged(true)
      closeDialog()
    } catch (error) {
      setPending(false)
      console.error(error)
      setFlash({
        kind: 'error',
        message:
          'Désolé, nous n’avons pas pu traiter votre demande. Veuillez réessayer plus tard',
      })
    }
  }

  function openDialog(event) {
    event.preventDefault()
    setActive(true)
  }
}

ReviewFormDialog.propTypes = {
  // Do we ask customers whether they want their identifier/name to be displayed
  // with their review?
  askForIdentifierDisplay: bool,
  noReviews: bool.isRequired,
  url: string.isRequired,
}
